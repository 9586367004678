import { createComponent } from '@/helpers/alpine';
import { CustomerLocation } from '@/services/customer-location';

const RelaySelector = createComponent(() => ({
    query: '' as string,
    noResults: false,
    geocoder: undefined as google.maps.Geocoder | undefined,
    _results: [] as google.maps.GeocoderResult[],

    get results() {
        if (
            CustomerLocation.hasLocation &&
            this.query == CustomerLocation.address
        ) {
            return [];
        }

        return this._results.slice(0, 3);
    },

    async search() {
        let res;

        try {
            res = await this.geocoder?.geocode({ address: this.query });
        } catch (err) {
            if (
                err instanceof google.maps.MapsRequestError &&
                err.code == google.maps.GeocoderStatus.ZERO_RESULTS
            ) {
                this.noResults = true;
                this._results = [];
                return;
            }
        }

        this.noResults = false;
        this._results = res?.results ?? [];
    },

    select(result: google.maps.GeocoderResult) {
        CustomerLocation.set(result);

        this.query = CustomerLocation.address ?? '';
        this.noResults = false;
        this._results = [];
    },

    async setupRelay() {
        this.query = CustomerLocation.address ?? '';
        this.geocoder = new google.maps.Geocoder();
    },
}));

export { RelaySelector };
